import { Link } from "gatsby"
import React from "react"
import headerStyles from './header.module.css';

const Header = () => (
  <header className={headerStyles.header}>
    <div className={headerStyles.inside}>
      <img src={'/images/logo.svg'} className={headerStyles.logo} alt="Schauenburg HOSE TECHNOLOGY GROUP"/>

      <Link to={'/en'} className={headerStyles.languageSwitch}>ENGLISH</Link>
    </div>
  </header>
)

export default Header
